import React from "react";
import BraceletsSection from "./BraceletsSection";
import Header from "../../components/Header/Header";

const HomePage = () => {
  return (
    <div className='flex flex-col w-full gap-10'>
      <Header />

      <BraceletsSection />
    </div>
  );
};

export default HomePage;
