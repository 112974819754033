export const getDeliveryStatusName = (status) => {
  switch (status) {
    case "COLLECTING_ORDER":
      return "Preparing order...";
    case "DELIVERING":
      return "Delivering...";
    case "DELIVERED":
      return "Delivered ✅";
  }
};
