import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { findIndex } from "lodash";

export const getCurrentUser = createAsyncThunk(
  "user/me",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get("/user/me");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const updateUserData = createAsyncThunk(
  "user/updateUserData",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/user/update", body?.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getCartItems = createAsyncThunk(
  "user/getCartItems",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get("/user/cart");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getOrders = createAsyncThunk(
  "user/orders",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get("/user/orders");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getCartItemsForOrder = createAsyncThunk(
  "user/getCartItemsForOrder",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/user/cartItemsForOrder", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const addProductToCart = createAsyncThunk(
  "user/addProductToCart",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/user/cart/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const removeProductFromCart = createAsyncThunk(
  "user/removeProductFromCart",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/user/cart/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getRegions = createAsyncThunk(
  "user/getRegions",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get("/user/regions");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getDeliveryAddressesByRegionId = createAsyncThunk(
  "user/getDeliveryAddressesByRegionId",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/user/deliveryAddresses/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const purchaseOrder = createAsyncThunk(
  "user/purchaseOrder",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/user/purchase`, body?.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    cart: [],
    cartItemsForOrder: null,

    orders: null,

    regions: null,
    deliveryAddresses: null,

    isLoggedIn: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    logout: (state) => {
      state.currentUser = null;
      state.isLoggedIn = false;
      localStorage.removeItem("token");
      localStorage.removeItem("refresh-token");
      window.location.href = "/signIn";
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET regions ------------------/////
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currentUser = payload;
      })
      .addCase(getCurrentUser.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.status === 500) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh-token");
          window.location.href = "/";
        }

        state.error = payload;
      })

      ///------------ GET regions ------------------/////
      .addCase(updateUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currentUser = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateUserData.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      })

      ///------------ GET regions ------------------/////
      .addCase(getCartItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartItems.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cart = payload?.data;
      })
      .addCase(getCartItems.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      })

      ///------------ GET regions ------------------/////
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.orders = payload?.data;
      })
      .addCase(getOrders.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      })

      ///------------ GET regions ------------------/////
      .addCase(getCartItemsForOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartItemsForOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartItemsForOrder = payload?.data;
      })
      .addCase(getCartItemsForOrder.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      })

      ///------------ CREATE collections ------------------/////
      .addCase(addProductToCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductToCart.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.cart, {
          id: payload?.data?.id,
        });

        if (ctgIndex !== -1) {
          state.cart[ctgIndex] = payload?.data;
        } else {
          state.cart = [payload?.data, ...state.cart];
        }

        toast.success(payload?.message);
      })
      .addCase(addProductToCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ CREATE collections ------------------/////
      .addCase(removeProductFromCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeProductFromCart.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.cart, {
          id: payload?.data?.id,
        });

        if (payload?.data?.quantity === 0) {
          state.cart.splice(ctgIndex, 1);
        } else {
          state.cart[ctgIndex] = payload?.data;
        }
        toast.success(payload?.message);
      })
      .addCase(removeProductFromCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ GET regions ------------------/////
      .addCase(getRegions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRegions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.regions = payload?.data;
      })
      .addCase(getRegions.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      })

      ///------------ GET regions ------------------/////
      .addCase(getDeliveryAddressesByRegionId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getDeliveryAddressesByRegionId.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.deliveryAddresses = payload?.data;
        }
      )
      .addCase(
        getDeliveryAddressesByRegionId.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        }
      )

      ///------------ CREATE collections ------------------/////
      .addCase(purchaseOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(purchaseOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        window.location.href = payload?.data?.paymentUrl;

        // toast.success(payload?.message);
      })
      .addCase(purchaseOrder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      });
  },
});

export const { resetError, logout } = userSlice.actions;

export default userSlice.reducer;
