import React from "react";
import logoB from "../../assets/unico_logo1.svg";
import { Link } from "@nextui-org/react";

const Footer = () => {
  return (
    <div className='flex relative w-full h-[150px] md:h-[180px] rounded-[30px] md:rounded-[40px] bg-[#E0AEF7]'>
      <div className='flex flex-row justify-between md:grid md:grid-cols-3 px-3 md:px-8 items-center absolute w-full h-[80px] md:h-[100px] top-0 left-0 rounded-[30px] md:rounded-[40px] bg-[#ECC6FF]'>
        <img src={logoB} className='w-[50px] md:w-[60px]' />
        <div className='flex flex-row w-full justify-end px-3 md:px-0 md:justify-center text-[12px] md:text-[14px] gap-3 md:gap-5'>
          <Link className='text-neutral-700 text-[14px]' href='/'>
            Home
          </Link>
          <Link className='text-neutral-700 text-[14px]' href='/bracelets'>
            Bracelets
          </Link>
          <Link className='text-neutral-700 text-[14px]' href='/aboutUs'>
            About Us
          </Link>
          <Link className='text-neutral-700 text-[14px]' href='/contact'>
            Contact
          </Link>
        </div>

        <div className='hidden md:flex flex-row gap-0 items-center justify-end'>
          <Link
            href='/me/cart'
            className='flex justify-center items-center w-[40px] h-[40px] rounded-full'
          >
            <img
              src='https://cdn.hugeicons.com/icons/shopping-bag-02-stroke-rounded.svg'
              alt='shopping-bag-02'
              className='w-[19px] h-[19px] md:w-[24px] md:h-[24px]'
            />
          </Link>

          <Link
            href='/me/profile'
            className='flex justify-center items-center w-[40px] h-[40px] rounded-full'
          >
            <img
              src='https://cdn.hugeicons.com/icons/user-stroke-rounded.svg'
              alt='user'
              className='w-[19px] h-[19px] md:w-[24px] md:h-[24px]'
            />
          </Link>
        </div>
      </div>

      <div className='md:grid flex flex-row justify-between md:grid-cols-3 text-[14px] font-light px-5 md:px-8 items-center absolute w-full h-[70px] md:h-[80px] bottom-0 left-0 rounded-[30px] md:rounded-[40px]'>
        <div className='flex md:hidden flex-col items-start'>
          <span className='text-[11px] md:text-[14px]'>
            {"Copyright 2024. All rights reserved."}
          </span>
          <span className='text-[12px] md:text-[14px]'>
            {"Powered by  🔥 Olov Soft."}
          </span>
        </div>
        <span className='hidden md:flex text-[12px] md:text-[14px]'>
          {"Copyright 2024. All rights reserved."}
        </span>
        <div className='hidden md:flex w-full justify-center'>
          <span className='text-[12px] md:text-[14px]'>
            {"Powered by  🔥 Olov Soft."}
          </span>
        </div>

        <div className='flex flex-row justify-end items-center'>
          <Link
            href='https://t.me/unico_store_uz'
            className='flex justify-center items-center w-[40px] h-[40px] rounded-full'
          >
            <img
              src='https://cdn.hugeicons.com/icons/telegram-stroke-rounded.svg'
              alt='telegram'
              width='24'
              height='24'
            />
          </Link>

          <Link
            href='https://instagram.com/unico.store.uz'
            className='flex justify-center items-center w-[40px] h-[40px] rounded-full'
          >
            <img
              src='https://cdn.hugeicons.com/icons/instagram-stroke-rounded.svg'
              alt='instagram'
              width='24'
              height='24'
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
