import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

export const ProtectRoutes = () => {
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to='/signIn' replace />;
};

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return !token ? (
    <Outlet />
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  );
};

export const ProtectedComponent = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? children : null;
};

export const AuthenticatedComponent = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return !token ? children : null;
};

// export const UnverifiedUserRoutes = () => {
//   const location = useLocation();
//   const { unverifiedUser } = useAuth();
//   return unverifiedUser ? (
//     <Outlet />
//   ) : (
//     <Navigate to='/' state={{ from: location }} replace />
//   );
// };
