import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";

import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import SignInPage from "./pages/SignInPage/SignInPage";
import BraceletsPage from "./pages/BraceletsPage/BraceletsPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import AuthTokens from "./pages/AuthPage/AuthTokens";
import AuthTelegram from "./pages/AuthPage/AuthTelegram";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCartItems, getCurrentUser } from "./pages/UserPage/userSlice";
import BraceletPage from "./pages/BraceletsPage/BraceletPage/BraceletPage";
import { ToastContainer } from "react-toastify";
import UserPage from "./pages/UserPage/UserPage";
import PurchasePage from "./pages/UserPage/PurchasePage/PurchasePage";
import {
  AuthenticatedRoutes,
  ProtectRoutes,
} from "./customHooks/protectedRoutes";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.getItem("token") && dispatch(getCurrentUser());
    localStorage.getItem("token") && dispatch(getCartItems());
  }, []);

  return (
    <div className='scrollbar-hide overflow-y-scroll flex flex-col gap-3 md:gap-5 bg-white py-2 px-3 md:py-5 md:px-8'>
      <Navbar />
      <Routes>
        <Route element={<AuthenticatedRoutes />}>
          <Route path='/signIn' element={<SignInPage />} />
        </Route>
        <Route element={<ProtectRoutes />}>
          <Route path='/me/*' element={<UserPage />} />
          <Route path='/purchase/:id' element={<PurchasePage />} />
        </Route>
        <Route path='/auth/tokens/:id' element={<AuthTokens />} />
        <Route path='/auth/telegram' element={<AuthTelegram />} />
        <Route path='/bracelets' element={<BraceletsPage />} />
        <Route path='/bracelet/:id' element={<BraceletPage />} />
        <Route path='/aboutUs' element={<AboutUsPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/' element={<HomePage />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>

      <Footer />

      <ToastContainer
        // style={{ fontWeight: 500, color: "black" }}
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>
  );
}

export default App;
