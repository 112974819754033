import * as Yup from "yup";
import date from "date-and-time";
import { getPhoneNumberPattern } from "../../../utils/regex";

export const emptyValues = {
  phoneNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  birthDate: "",
};

export const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  firstName: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
  lastName: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
  email: Yup.string()
    .email("Yaroqsiz email!")
    .required("Bo'sh bo'lmasligi kerak!"),
  birthDate: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
});

export const getUpdateInitialValues = (user = {}) => {
  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: getPhoneNumberPattern(user?.phoneNumber),
    birthDate: date.format(new Date(user?.birthDate), "YYYY-MM-DD"),
  };
};
