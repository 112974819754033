import { CheckboxGroup } from "@nextui-org/react";
import React, { useEffect } from "react";
import CartItemCheckBox from "../../../components/Cart/CartItemCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "../userSlice";
import { getImageSrc } from "../../../utils/image";
import { getMoneyPattern } from "../../../utils/regex";
import { CheckProductDetailStock } from "../../../utils/product";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const [groupSelected, setGroupSelected] = React.useState([]);

  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartItems());
  }, []);

  return (
    <div className='flex flex-col w-full col-span-3'>
      <span className='md:hidden flex text-[18px] font-bold px-5 pt-3'>
        Cart
      </span>

      {cart && cart.length === 0 ? (
        <div className='flex items-center justify-center p-5'>
          <span>Your cart is empty.</span>
        </div>
      ) : (
        <div className='flex flex-col gap-1 w-full  p-3 md:p-5 rounded-2xl'>
          <CheckboxGroup
            label='Select products'
            value={groupSelected}
            onChange={setGroupSelected}
            className='w-full'
          >
            {cart &&
              cart.map((cartItem) => (
                <CartItemCheckBox
                  key={cartItem?.id}
                  cartItem={cartItem}
                  value={cartItem?.id}
                  user={{
                    name: cartItem?.productVariant?.name,
                    avatar: getImageSrc(cartItem?.productVariant?.image),
                    username: "jrgarciadev",
                    url: "https://twitter.com/jrgarciadev",
                    role: "Software Developer",
                    status: "Active",
                  }}
                  statusColor='secondary'
                />
              ))}
          </CheckboxGroup>
          <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-3 md:gap-0 p-5'>
            <span className='text-[12px] md:text-[14px] font-semibold'>{`Total price: ${getMoneyPattern(
              cart
                .filter((el) => groupSelected.find((id) => id === el?.id))
                .reduce(
                  (acc, entry) =>
                    acc + entry?.quantity * entry?.productVariant?.price,
                  0
                ),
              ","
            )} UZS`}</span>

            <button
              style={{
                backgroundColor:
                  groupSelected.length === 0 ? "#bbb" : "#ECC6FF",
              }}
              disabled={groupSelected.length === 0}
              onClick={() => {
                if (
                  CheckProductDetailStock(
                    cart.filter((el) =>
                      groupSelected.find((id) => id === el?.id)
                    )
                  )
                ) {
                  toast.error("Out of stock or unable to deliver");
                } else {
                  navigate(`/purchase/${btoa(JSON.stringify(groupSelected))}`);
                }
              }}
              className='flex flex-row gap-2 w-full md:w-fit text-[14px] justify-center items-center bg-[#ECC6FF] rounded-full p-5'
            >
              <img
                src='https://cdn.hugeicons.com/icons/shopping-cart-check-02-stroke-rounded.svg'
                alt='shopping-cart-check-02'
                width='24'
                height='24'
              />
              Buy now -
              <span className='text-[14px] font-semibold'>{`${getMoneyPattern(
                cart
                  .filter((el) => groupSelected.find((id) => id === el?.id))
                  .reduce(
                    (acc, entry) =>
                      acc + entry?.quantity * entry?.productVariant?.price,
                    0
                  ),
                ","
              )} UZS`}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
