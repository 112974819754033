import * as Yup from "yup";

export const deliveryHours = [
  {
    id: "OPTION_1",
    name: "9:00 - 10:00",
  },
  {
    id: "OPTION_2",
    name: "10:00 - 11:00",
  },
  {
    id: "OPTION_3",
    name: "14:00 - 15:00",
  },
  {
    id: "OPTION_4",
    name: "16:00 - 17:00",
  },
];

export const geteEmptyValues = (deliveryDate) => ({
  phoneNumber: "",
  deliveryAddressId: "",
  regionId: "",
  paymentType: "",
  deliveryDate,
  deliveryHours: "OPTION_1",
});

export const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, "Tel. raqamni to'g'ri kiriting!")
    .required("Bo'sh bo'lmasligi kerak!"),
  deliveryAddressId: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
  regionId: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
  paymentType: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
  deliveryDate: Yup.string(),
  deliveryHours: Yup.string(),
});
