import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  User,
} from "@nextui-org/react";
import { menus } from "./data";

const MenuHamburger = () => {
  return (
    <Dropdown placement='bottom-start'>
      <DropdownTrigger>
        <div className='flex md:hidden relative justify-center items-center md:w-[40px] md:h-[40px] w-[38px] h-[38px]'>
          <img
            src='https://cdn.hugeicons.com/icons/menu-05-stroke-rounded.svg'
            alt='menu-11'
            className='w-[20px] h-[20px]'
          />
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label='Menu' variant='flat'>
        {menus.map((menu) => (
          <DropdownItem key={menu.name} href={menu.path}>
            {menu.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuHamburger;
