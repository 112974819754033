import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearProduct, getProductById } from "../../Product/productSlice";
import { getImageSrc } from "../../../utils/image";
import { getMoneyPattern } from "../../../utils/regex";
import { getDetailName } from "../../../utils/product";
import {
  addProductToCart,
  removeProductFromCart,
} from "../../UserPage/userSlice";

const BraceletPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [prDetails, setPrDetails] = useState({});

  const [selectedProductVariant, setSelectedProductVariant] = useState();

  const { product } = useSelector((state) => state.products);
  const { cart } = useSelector((state) => state.user);

  useEffect(() => {
    !product && dispatch(getProductById({ id }));
  });

  const changeSelection = (detail) => {
    let tempPvArr = selectedProductVariant?.productDetails.map((tpd) => {
      if (tpd.name === detail?.name) {
        return { ...tpd, id: detail?.id }; // Create a new object with the updated id
      }
      return tpd;
    });

    product?.productVariants.forEach((pv) => {
      if (
        pv?.productDetails.every((el) =>
          tempPvArr.find((tppd) => tppd?.id === el?.id)
        )
      ) {
        setSelectedProductVariant(pv);
      }
    });
  };

  const isOnStock = Boolean(
    selectedProductVariant?.productDetails.some((pd) => pd?.stock <= 0)
  );

  useEffect(() => {
    product && setSelectedProductVariant(product?.productVariants[0]);

    let myObj = {};

    if (product) {
      product?.productVariants?.forEach((pv) => {
        pv.productDetails.map((pd) => {
          if (!(pd?.name in myObj)) {
            myObj[pd?.name] = [];
          }

          if (!myObj[pd?.name]?.some((el) => el.id === pd?.id)) {
            let arr = myObj[pd?.name];
            arr.push(pd);
            myObj[pd?.name] = arr;
          }
        });
      });
    }
    setPrDetails(myObj);
  }, [product]);

  return (
    <div className='flex flex-col w-full p-2 md:p-5 bg-[#F2F2F2] rounded-[30px] md:rounded-[50px]'>
      {product && selectedProductVariant && (
        <div className='grid grid-cols-5 w-full gap-3 md:gap-5'>
          <img
            className='col-span-5 md:col-span-2 w-full aspect-square object-cover rounded-[30px]'
            src={getImageSrc(selectedProductVariant?.image)}
          />
          <div className='flex flex-col justify-between gap-3 md:gap-5 p-5 md:p-8 w-full col-span-5 md:col-span-3 bg-white rounded-[30px]'>
            <span className='text-[28px] md:text-[32px] font-semibold'>
              {product?.name}
            </span>
            <div className='flex flex-row flex-wrap gap-5'>
              <pre className='text-[14px] font-sora break-words overflow-auto whitespace-pre-wrap'>
                {product?.description}
              </pre>
            </div>
            <div className='flex flex-col'>
              <span className='text-[28px] md:text-[32px] font-semibold'>
                {getMoneyPattern(selectedProductVariant?.price, ",") + " UZS"}
              </span>
              <span className='text-[14px] md:text-[16px] font-semibold text-[#640DCA]'>
                {selectedProductVariant?.name}
              </span>
            </div>

            <div className='grid md:grid-cols-2 w-full gap-3 md:gap-0'>
              {prDetails &&
                Object.keys(prDetails).map((pd) => (
                  <div key={pd} className='flex flex-col gap-1 md:gap-3'>
                    <span className='text-[12px] md:text-[14px] font-semibold'>
                      {getDetailName(pd)}
                    </span>
                    <div className='flex flex-row gap-3'>
                      {prDetails[pd] &&
                        prDetails[pd].map((cl) => (
                          <button
                            onClick={() => changeSelection(cl)}
                            key={cl?.id}
                            className='flex flex-col items-center gap-1'
                          >
                            <div
                              style={{
                                backgroundColor:
                                  selectedProductVariant?.productDetails.some(
                                    (el) => el.id === cl?.id
                                  )
                                    ? "#E0AEF74D"
                                    : "#fefefe",
                                borderColor:
                                  selectedProductVariant?.productDetails.some(
                                    (el) => el.id === cl?.id
                                  )
                                    ? "#E0AEF7"
                                    : "#eee",
                              }}
                              className='flex relative w-[80px] h-[80px] p-2 justify-center items-center border-[1.5px] rounded-2xl border-[#E0AEF7]'
                            >
                              {cl?.stock <= 0 && (
                                <>
                                  <div className='absolute w-[2px] h-full rotate-45 bg-red-500' />
                                  <div className='absolute w-[2px] h-full rotate-[-45deg] bg-red-500' />
                                </>
                              )}
                              <img
                                className='w-full aspect-square object-cover'
                                src={getImageSrc(cl?.image)}
                              />
                            </div>
                            <span className='text-[10px] md:text-[12px]'>
                              {cl?.value}
                            </span>
                          </button>
                        ))}
                    </div>
                  </div>
                ))}
            </div>

            {/* <div className='grid grid-cols-2 w-full'>
              <div className='flex flex-col gap-3'>
                <span className='text-[14px] font-semibold'>Select Color</span>
                <div className='flex flex-row gap-3'>
                  {colors &&
                    colors.map((cl) => (
                      <div
                        key={cl?.id}
                        className='flex flex-col items-center gap-1'
                      >
                        <div className='flex w-[80px] h-[80px] p-2 justify-center items-center border-[1.5px] rounded-2xl border-[#E0AEF7] bg-[#E0AEF7]/20'>
                          <img
                            className='w-full aspect-square object-cover'
                            src={getImageSrc(product?.image)}
                          />
                        </div>
                        <span className='text-[12px]'>{cl?.value}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <span className='text-[14px] font-semibold'>Select Band</span>
                <div className='flex flex-row gap-3'>
                  {bands &&
                    bands.map((band) => (
                      <div
                        key={band?.id}
                        className='flex flex-col items-center gap-1'
                      >
                        <div className='flex w-[80px] h-[80px] p-2 justify-center items-center border-[1.5px] rounded-2xl border-[#E0AEF7] bg-[#E0AEF7]/20'>
                          <img
                            className='w-full aspect-square object-cover'
                            src={getImageSrc(product?.image)}
                          />
                        </div>
                        <span className='text-[12px]'>{band?.value}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div> */}

            <div className='flex flex-row gap-2 md:gap-5 w-full'>
              <button
                onClick={() => {
                  if (
                    cart?.find(
                      (el) =>
                        el.productVariant?.id === selectedProductVariant?.id
                    )
                  ) {
                    navigate("/me/cart");
                  } else {
                    localStorage.getItem("token")
                      ? dispatch(
                          addProductToCart({ id: selectedProductVariant?.id })
                        )
                      : navigate("/signIn");
                  }
                }}
                style={{ backgroundColor: isOnStock ? "#eee" : "#ECC6FF" }}
                disabled={isOnStock}
                className='flex flex-row grow gap-1 md:gap-2 text-[12px] md:text-[14px] justify-center items-center bg-[#ECC6FF] rounded-full p-3 md:p-5'
              >
                <img
                  src='https://cdn.hugeicons.com/icons/shopping-basket-add-03-stroke-rounded.svg'
                  alt='shopping-basket-add-03'
                  width='24'
                  height='24'
                />
                <span>
                  {cart?.find(
                    (el) => el.productVariant?.id === selectedProductVariant?.id
                  )
                    ? "Buy now"
                    : "Add to cart"}
                </span>
                <span>-</span>
                <span className='text-[14px] font-semibold'>
                  {getMoneyPattern(selectedProductVariant?.price, ",") + " UZS"}
                </span>
              </button>

              {cart?.find(
                (el) => el.productVariant?.id === selectedProductVariant?.id
              ) && (
                <div className='flex flex-row gap-2 justify-center items-center md:w-[120px] px-1 md:px-0 border-[1.5px] border-black rounded-full'>
                  <button
                    onClick={() => {
                      dispatch(
                        removeProductFromCart({
                          id: selectedProductVariant?.id,
                        })
                      );
                    }}
                  >
                    <img
                      src='https://cdn.hugeicons.com/icons/minus-sign-circle-stroke-rounded.svg'
                      alt='minus-sign-circle'
                      className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]'
                    />
                  </button>
                  <span>
                    {
                      cart?.find(
                        (el) =>
                          el.productVariant?.id == selectedProductVariant?.id
                      )?.quantity
                    }
                  </span>
                  <button
                    onClick={() => {
                      dispatch(
                        addProductToCart({ id: selectedProductVariant?.id })
                      );
                    }}
                  >
                    <img
                      src='https://cdn.hugeicons.com/icons/plus-sign-circle-stroke-rounded.svg'
                      alt='plus-sign-circle'
                      className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]'
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BraceletPage;
