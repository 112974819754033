import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";

export const getCollectionByName = createAsyncThunk(
  "collections/byName",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `/public/collection/byName?name=${body?.name}`
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const collectionsSlice = createSlice({
  name: "collections",
  initialState: {
    collection: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionByName.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCollectionByName.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.collection = payload?.data;
      })
      .addCase(getCollectionByName.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      });
  },
});

export const { resetError } = collectionsSlice.actions;

export default collectionsSlice.reducer;
