import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../userSlice";
import {
  Accordion,
  AccordionItem,
  Avatar,
  AvatarGroup,
  Divider,
  Link,
} from "@nextui-org/react";
import { getImageSrc } from "../../../utils/image";
import { useNavigate } from "react-router-dom";
import { getDeliveryStatusName } from "../../../utils/order";
import { getMoneyPattern } from "../../../utils/regex";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orders } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  return (
    <div className='flex flex-col bg-white gap-1 w-full col-span-3 p-5 rounded-2xl'>
      <span className='md:hidden flex text-[18px] font-bold'>My orders</span>
      {orders &&
        (orders.length === 0 ? (
          <div className='flex items-center justify-center p-5'>
            <span>You have not orders yet.</span>
          </div>
        ) : (
          <Accordion selectionMode='multiple'>
            {orders.map((order) => (
              <AccordionItem
                key={order?.id}
                aria-label={order?.status}
                startContent={
                  <AvatarGroup isBordered>
                    {order?.ProductOrderItems?.map((item) => (
                      <Avatar
                        size='md'
                        color='primary'
                        className='cursor-pointer'
                        key={item?.id}
                        src={getImageSrc(item?.productVariant?.image)}
                      />
                    ))}
                  </AvatarGroup>
                }
                subtitle={
                  <div className='flex flex-row gap-2 md:gap-5 items-center'>
                    <span className='text-[12px] md:text-[14px] font-bold text-[#640DCA]'>{`${getMoneyPattern(
                      order?.totalPrice
                    )} UZS`}</span>
                    <span className='text-[10px] md:text-[14px]'>{`${order?.ProductOrderItems.reduce(
                      (acc, entry) => acc + entry?.quantity,
                      0
                    )} items`}</span>
                  </div>
                }
                title={
                  <span className='text-[12px] md:text-[16px]'>
                    {getDeliveryStatusName(order?.deliveryStatus)}
                  </span>
                }
              >
                <div className='grid md:grid-cols-2 w-full gap-5 pb-5'>
                  <div className='flex flex-col w-full gap-3'>
                    <div className='flex flex-row gap-3 w-full flex-wrap'>
                      {order?.ProductOrderItems?.map((item) => (
                        <button
                          onClick={() =>
                            navigate(
                              `/bracelet/${item?.productVariant?.product?.id}`
                            )
                          }
                          key={item?.id}
                          className='flex flex-col items-center p-1 bg-[#ECC6FF]/50 w-[70px] md:w-[80px] rounded-2xl'
                        >
                          <img
                            className='rounded-xl md:rounded-2xl'
                            src={getImageSrc(item?.productVariant?.image)}
                          />
                          <span className='text-[10px] md:text-[12px] font-bold text-center'>{`${item?.quantity}X`}</span>
                        </button>
                      ))}
                    </div>
                    <span className='font-bold'>{`${getMoneyPattern(
                      order?.ProductOrderItems.reduce(
                        (acc, entry) =>
                          acc + entry?.quantity * entry?.productVariant?.price,
                        0
                      ),
                      ","
                    )} UZS`}</span>
                    <div className='flex flex-row text-[16px] gap-5 items-center w-full'>
                      <span className='text-[12px] md:text-[14px]'>
                        Delivery Price
                      </span>
                      <span className='font-bold'>
                        {`${getMoneyPattern(
                          order?.deliveryAddress?.price,
                          ","
                        )} UZS`}
                      </span>
                    </div>
                    <Divider className='my-[2px] bg-[#ECC6FF]' />
                    <div className='flex flex-row text-[16px] gap-5 items-center w-full'>
                      <span className='text-[14px] md:text-[16px]'>
                        Total Price
                      </span>
                      <span className='font-bold text-[18px]'>
                        {`${getMoneyPattern(order?.totalPrice, ",")} UZS`}
                      </span>
                    </div>
                    {/* <Divider className='my-[2px] bg-[#ECC6FF]' /> */}
                  </div>
                  <div className='flex relative flex-col pb-8 gap-1 bg-white py-8 md:p-5 rounded-3xl'>
                    {!order?.deliveryAddress?.isPrivate && (
                      <img
                        className='w-[80px] absolute top-4 right-4'
                        src='https://bts.uz/img/logo.svg'
                      />
                    )}
                    <div className='flex flex-row '>
                      <span className='text-[16px] font-bold'>
                        {order?.deliveryAddress?.name}
                      </span>
                    </div>
                    <span className='text-[14px] mt-3'>
                      Address 1:
                      <span className='text-[14px] font-semibold mx-2'>
                        {order?.deliveryAddress?.address1}
                      </span>
                    </span>
                    {!order?.deliveryAddress?.isPrivate && (
                      <>
                        <span className='text-[14px]'>
                          Address 2:
                          <span className='text-[14px] font-semibold mx-2'>
                            {order?.deliveryAddress?.address2}
                          </span>
                        </span>
                        <span className='text-[14px]'>
                          Map:
                          <Link
                            target='_blank'
                            href={order?.deliveryAddress?.mapData}
                            className='text-[14px] font-semibold mx-2'
                          >
                            View on map
                          </Link>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        ))}
    </div>
  );
};

export default OrdersPage;
