import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AuthTokens = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { data1: accessToken, data2: refreshToken } = DecodeTokens(id);
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refresh-token", refreshToken);
    navigate("/");
  });

  //   useEffect(() => {
  //     let data1 = "ChatGPT";
  //     let data2 = "ReactJS";

  //     let encodedString = EncodeTokens(data1, data2);
  //     console.log(encodedString);
  //   }, []);

  return (
    <div className='flex justify-center items-center w-full h-[300px]'>
      Signing In...
    </div>
  );
};

function EncodeTokens(data1, data2) {
  // Combine data strings
  let combinedData = data1 + ":" + data2;

  // Encode to Base64
  let encodedString = btoa(combinedData);

  return encodedString;
}

function DecodeTokens(encodedString) {
  // Decode Base64 string
  let decodedString = atob(encodedString);

  console.log(decodedString);

  // Split decoded string into data1 and data2
  let [data1, data2] = decodedString.split(":");

  return { data1, data2 };
}

export default AuthTokens;
