import React from "react";

const SignInPage = () => {
  return (
    <div className='flex justify-center items-center w-full h-[500px]'>
      <div className='flex flex-col items-center bg-white w-[600px] rounded-3xl p-5 shadow-xl border-black border-[1.3px]'>
        <span className='text-[24px]'>Sign In</span>

        <div className='flex flex-col gap-3 my-5 text-[14px] md:text-[16px] items-center w-full px-2 md:px-10'>
          <a
            href='https://api.unicostore.uz/v1/api/auth/login/google'
            className='flex flex-row w-fit gap-5 items-center text-white bg-blue-500 p-2 rounded-full'
          >
            <img
              src='https://cdn.iconscout.com/icon/free/png-256/free-google-1772223-1507807.png'
              className='w-[40px] bg-white p-[7px] rounded-full'
            />
            <span className='mr-5'>Sign In with Google Account</span>
          </a>

          <a
            href='https://oauth.telegram.org/auth?bot_id=7130953176&embed=1&request_access=write&origin=https://unicostore.uz/auth/telegram'
            className='flex flex-row w-fit gap-5 items-center text-white bg-sky-500 p-2 rounded-full'
          >
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png'
              className='w-[40px] bg-white p-[7px] rounded-full'
            />
            <span className='mr-5'>Sign In with Telegram</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
