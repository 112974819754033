import React from "react";
import bracelet from "../../assets/bracelet1.png";
import { getMoneyPattern } from "../../utils/regex";
import { getImageSrc } from "../../utils/image";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearProduct } from "../../pages/Product/productSlice";

const BraceletCard = ({ data, collectionName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <button
      onClick={() => {
        dispatch(clearProduct());

        navigate(`/bracelet/${data?.id}`);
      }}
      className='relative flex flex-col justify-center items-start md:items-center bg-[#F2F2F2] w-full aspect-square rounded-[25px] md:rounded-[30px] gap-0 p-0'
    >
      <span className='text-[9px] z-50 md:text-[12px] absolute top-3 left-2 md:top-5 md:left-4 p-1 md:p-2 bg-white rounded-full'>
        {`✨ ${collectionName} ✨`}
      </span>
      <div className='absolute scale-[90%] top-2 md:top-0  w-full aspect-square bg-gradient-to-t visionLook bottom-0 z-10' />
      <div className='absolute scale-[90%] top-2 md:top-0  w-full aspect-square rotate-90 visionLook left-0 z-10' />
      <img
        src={getImageSrc(data?.image)}
        className='w-full absolute top-2 md:top-0  aspect-square object-cover scale-[90%] z-0'
      />

      <div className='md:absolute z-10 bottom-0 left-0 flex flex-row justify-between h-[130%] items-end w-full p-3 md:p-5'>
        <div className='flex flex-col items-start gap-[2px] md:gap-2'>
          <span className='text-[12px] md:text-[15px] text-left line-clamp-1'>
            {data?.name}
          </span>
          <span className='text-[14px] md:text-[19px] font-semibold'>{`${getMoneyPattern(
            data?.price,
            ","
          )} UZS`}</span>
        </div>
        <div className='flex bg-[#EBFF9E] justify-center items-center w-[30px] h-[30px] md:w-[50px] md:h-[50px] rounded-full border-black border-[1.3px]'>
          <img
            src='https://cdn.hugeicons.com/icons/shopping-bag-02-stroke-rounded.svg'
            alt='shopping-bag-02'
            className='md:w-[20px] md:h-[20px] w-[15px] h-[15px]'
          />
        </div>
      </div>
    </button>
  );
};

export default BraceletCard;
