import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  User,
} from "@nextui-org/react";
import { useDispatch } from "react-redux";
import { logout } from "../../pages/UserPage/userSlice";

const NavbarHamburger = ({ currentUser }) => {
  const dispatch = useDispatch();
  return (
    <Dropdown placement='bottom-end'>
      <DropdownTrigger>
        <div className='flex md:hidden relative justify-center items-center md:w-[40px] md:h-[40px] w-[38px] h-[38px]'>
          <div className='flex bg-white justify-center items-center md:w-[40px] md:h-[40px] w-[38px] h-[38px] rounded-full border-black border-[1.3px]'>
            {/* <img
                src='https://cdn.hugeicons.com/icons/user-stroke-rounded.svg'
                alt='user'
                className="w-[20px] h-[20px]"
              /> */}

            <img
              src={currentUser?.avatarUrl}
              alt='user'
              className='w-full h-full rounded-full'
            />
          </div>
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label='User Actions' variant='flat'>
        <DropdownItem key='profile' className='flex flex-col items-start gap-5'>
          <img
            src={currentUser?.avatarUrl}
            alt='user'
            className='rounded-full w-[50px] h-[50px]'
          />
          <p className='font-bold mt-3'>{currentUser?.firstName}</p>
        </DropdownItem>
        <DropdownItem key='profile' href='/me/profile'>
          Profile
        </DropdownItem>
        <DropdownItem key='cart' href='/me/cart'>
          Cart
        </DropdownItem>
        <DropdownItem key='orders' href='/me/orders'>
          My Orders
        </DropdownItem>

        <DropdownItem
          onClick={() => dispatch(logout())}
          key='logout'
          color='danger'
          className='mt-5'
        >
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NavbarHamburger;
