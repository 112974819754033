import React from "react";
import { sidebarItems } from "./sidebar";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import UserProfilePage from "./UserProfilePage/UserProfilePage";
import OrdersPage from "./OrdersPage/OrdersPage";
import CartPage from "./CartPage/CartPage";
import { useDispatch } from "react-redux";
import { logout } from "./userSlice";

const UserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const IsCurrentPath = (p) => Boolean(location.pathname.startsWith(p));
  return (
    <div className='grid md:grid-cols-4 w-full p-2 md:p-5 gap-5 bg-[#F2F2F2] rounded-[30px] md:rounded-[50px]'>
      <div className='hidden md:flex flex-col items-start gap-2 w-full h-fit'>
        <div className='flex flex-col items-center gap-2 w-full h-fit bg-white rounded-3xl p-2'>
          {sidebarItems.map((item, index) => (
            <>
              <button
                key={index}
                onClick={() => navigate(item.path)}
                style={{
                  backgroundColor: IsCurrentPath(item?.path) && "#ECC6FF",
                }}
                className='flex flex-row w-full gap-5 items-center justify-start py-3 px-5 hover:bg-[#ECC6FF]/50 rounded-2xl'
              >
                <img src={item.iconUrl} alt='user' width='22' height='22' />
                <span className='text-[16px]'>{item.name}</span>
              </button>
              {index != sidebarItems.length - 1 && (
                <div className='w-[95%] bg-neutral-200 h-[1px]'></div>
              )}
            </>
          ))}
        </div>
        <button
          onClick={() => dispatch(logout())}
          className='flex flex-row items-center gap-2 bg-white rounded-2xl mt-5 p-3 w-fit'
        >
          <img
            src='https://cdn.hugeicons.com/icons/door-01-stroke-rounded.svg'
            alt='door-01'
            width='24'
            height='24'
          />
          <span className='text-[14px]'>Log out</span>
        </button>
      </div>

      <Routes>
        <Route path='/profile' element={<UserProfilePage />} />
        <Route path='/orders' element={<OrdersPage />} />
        <Route path='/cart' element={<CartPage />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </div>
  );
};

export default UserPage;
