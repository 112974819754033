import React from "react";
import logo from "../../assets/unico_logo.svg";
import { menus } from "./data";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AuthenticatedComponent,
  AuthenticatedRoutes,
  ProtectRoutes,
  ProtectedComponent,
} from "../../customHooks/protectedRoutes";
import { useSelector } from "react-redux";
import NavbarHamburger from "./NavbarHamburger";
import MenuHamburger from "./MenuHamburger";

const Navbar = () => {
  const navigate = useNavigate();
  const { currentUser, cart } = useSelector((state) => state.user);
  const loc = useLocation();
  return (
    <div className='grid grid-cols-3 justify-between bg-[#ECC6FF]/50 rounded-[19px] w-full h-[65px] md:h-[75px] items-center px-3 md:px-5'>
      <MenuHamburger />
      <div className='hidden md:flex flex-row text-[14px] gap-5'>
        {menus.map((el, index) => (
          <button
            key={index}
            onClick={() => navigate(el.path)}
            className={
              el.path == "/"
                ? loc.pathname == el.path
                  ? "underline underline-offset-8 font-semibold"
                  : ""
                : loc.pathname.startsWith(el.path)
                ? "underline underline-offset-8 font-semibold"
                : ""
            }
          >
            {el.name}
          </button>
        ))}
      </div>
      <div className='flex flex-row items-center justify-center'>
        <span className='text-[22px] font-normal hidden md:flex'>Unico</span>
        <img
          src={logo}
          className='w-[50px] h-[50px] md:w-[60px] md:h-[60px] mb-[3px]'
        />
        <span className='text-[22px] font-normal hidden md:flex'>Store</span>
      </div>
      <div className='flex flex-row gap-3 items-center justify-end'>
        <AuthenticatedComponent>
          <button
            onClick={() => {
              navigate("/signIn");
              // localStorage.setItem(
              //   "token",
              //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDk2MTYxMTUsInVzZXJfaWQiOiI0ZWZiMTZkMC00MjMzLTQxN2YtYjZlNi1mYWNlMjZmMmJlMzcifQ.2l3NjqPlOkqviA_VerJklZWfnQ37Ljf1louxAzI0nME"
              // );
              // localStorage.setItem(
              //   "refresh-token",
              //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTAzOTM3MTUsInVzZXJfaWQiOiI0ZWZiMTZkMC00MjMzLTQxN2YtYjZlNi1mYWNlMjZmMmJlMzcifQ.buDHZqpQ0jGrXA6xUpV6Zj6XbIt6m6voi7TjUote_jQ"
              // );
            }}
            className='flex text-[14px] font-normal h-[40px] px-3 bg-white justify-center items-center rounded-full border-black border-[1.3px]'
          >
            Sign In
          </button>
        </AuthenticatedComponent>

        <ProtectedComponent>
          <div className='flex flex-row items-center gap-3'>
            <button
              onClick={() => navigate("/me/cart")}
              className='hidden md:flex relative bg-white justify-center items-center md:w-[40px] md:h-[40px] w-[38px] h-[38px] rounded-full border-black border-[1.3px]'
            >
              {cart && cart.length > 0 && (
                <div className='absolute text-[12px] text-white right-[-5px] top-[-5px] rounded-full bg-[#D05D9F] w-[19px] h-[19px] flex justify-center items-center'>
                  {cart.length}
                </div>
              )}
              <img
                src='https://cdn.hugeicons.com/icons/shopping-bag-02-stroke-rounded.svg'
                alt='shopping-bag-02'
                className='w-[20px] h-[20px]'
              />
            </button>

            <button
              onClick={() => navigate("/me/profile")}
              className='hidden md:flex bg-white justify-center items-center md:w-[40px] md:h-[40px] w-[38px] h-[38px] rounded-full border-black border-[1.3px]'
            >
              {/* <img
                src='https://cdn.hugeicons.com/icons/user-stroke-rounded.svg'
                alt='user'
                className="w-[20px] h-[20px]"
              /> */}

              <img
                src={currentUser?.avatarUrl}
                alt='user'
                className='w-full h-full rounded-full'
              />
            </button>

            <NavbarHamburger currentUser={currentUser} />
          </div>
        </ProtectedComponent>
      </div>
    </div>
  );
};

export default Navbar;
