import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";

export const getProductById = createAsyncThunk(
  "products/getById",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/public/product/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    product: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearProduct: (state) => {
      state.product = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.product = payload?.data;
      })
      .addCase(getProductById.rejected, (state, { payload }) => {
        state.loading = false;

        state.error = payload;
      });
  },
});

export const { resetError, clearProduct } = productsSlice.actions;

export default productsSlice.reducer;
