import React from "react";
// import hImg from "../../assets/header.png";

const Header = () => {
  return (
    <div className='relative bg-black flex flex-col p-5 md:p-8 bg-cover bg-center bg-no-repeat w-full h-[350px] md:h-[500px] rounded-[30px] overflow-hidden '>
      <img
        className='w-full h-full bg-[url("../src/assets/header.png")] bg-cover bg-no-repeat absolute top-0 left-0 opacity-50'
        // src={hImg}
      />
      {/* <div className='top-0 left-0 absolute w-full h-full bg-black/20 backdrop-blur-sm backdrop-filter'></div> */}
      <span className='flex flex-col text-[32px] pr-10 md:text-[72px] font-bold m-0 absolute text-white'>
        <span>Discover the Magic ✨</span>
        <span>Explore Your AR Bracelet Today</span>
      </span>
    </div>
  );
};

export default Header;
