// const IMAGE_BASE_URL = "http://localhost:8080/v1/api/image/";
const IMAGE_BASE_URL = "https://api.unicostore.uz/v1/api/image/";

export const getImageSrc = (headerImage) => {
  return (
    headerImage !== null &&
    headerImage?.id &&
    `${IMAGE_BASE_URL}${headerImage?.id}`
    // `${IMAGE_BASE_URL}${headerImage?.id}?t=${new Date().getTime()}`
  );
};
