export const getDetailName = (name) => {
  switch (name) {
    case "br_color":
      return "Select color";
    case "br_band":
      return "Select band";
    default:
      return "Select detail";
  }
};

export function getDates() {
  const dates = [];
  const currentDate = new Date();

  // Start from the current date
  let nextDate = new Date(currentDate);

  // Add 3 days to the current date
  nextDate.setDate(currentDate.getDate() + 3);
  for (let i = 0; i < 5; i++) {
    dates.push(
      nextDate.getFullYear() +
        "-" +
        ("0" + (nextDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + nextDate.getDate()).slice(-2)
    );
    nextDate.setDate(nextDate.getDate() + 1);
  }

  return dates;
}

export const CheckProductDetailStock = (items = []) => {
  items.forEach((element) => {
    element?.productVariant?.productDetails.forEach((pd) => {
      if (pd?.stock <= 0 || element?.quantity > pd?.stock) {
        return true;
      }
    });
  });
  return false;
};
