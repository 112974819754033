import { Radio, cn } from "@nextui-org/react";
import React from "react";

const PaymentTypeRedio = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          "inline-flex m-0 bg-content1 hover:bg-white items-center justify-between",
          "flex-row-reverse w-full cursor-pointer rounded-3xl gap-1 px-4 py-2 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Radio>
  );
};

export default PaymentTypeRedio;
