import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthTelegram = () => {
  const location = useLocation();

  // Function to extract anchor value from URL
  const getAnchorValue = () => {
    const hashParams = new URLSearchParams(location.hash.substr(1)); // Remove '#' from the hash
    return hashParams.get("tgAuthResult");
  };

  useEffect(() => {
    // Call the function to get the anchor value
    const anchorValue = getAnchorValue();
    const externalUrl = `https://api.unicostore.uz/v1/api/auth/telegram?tgAuthResult=${anchorValue}`;
    window.location.href = externalUrl;
  }, []);
  return (
    <div className='flex justify-center items-center w-full h-[300px]'>
      Signing In...
    </div>
  );
};

export default AuthTelegram;
