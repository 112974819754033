import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../pages/UserPage/userSlice";
import collectionsReducer from "../pages/CollectionsPage/collectionsSlice";
import productsReducer from "../pages/Product/productSlice";
// import collectionReducer from "../pages/Products/CollectionsPage/collectionsSlice";
// import productReducer from "../pages/Products/ProductsPage/productsSlice";
// import productVariantReducer from "../pages/Products/ProductVariantsPage/productVariantsSlice";
// import productDetailReducer from "../pages/Products/ProductDetailsPage/productDetailsSlice";

// import regionReducer from "../pages/Delivery/RegionsPage/regionsSlice";
// import deliveryAddressReducer from "../pages/Delivery/DeliveryAdressesPage/deliveryAddressesSlice";

export default configureStore({
  reducer: {
    user: userReducer,

    collections: collectionsReducer,
    products: productsReducer,
    // products: productReducer,
    // productVariants: productVariantReducer,
    // productTypes: productTypeReducer,
    // collections: collectionReducer,
    // productDetails: productDetailReducer,

    // regions: regionReducer,
    // deliveryAddresses: deliveryAddressReducer,
  },
});
