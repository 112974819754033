import React from "react";
import { emptyValues, getUpdateInitialValues, validationSchema } from "./data";
import { getHandleChange, getReqBody } from "../../../components/Form/data";
import { Formik } from "formik";
import { Input } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../userSlice";

const UserProfilePage = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  return (
    <div className='flex flex-col w-full col-span-3 md:px-5'>
      <span className='md:hidden flex text-[18px] font-bold p-3'>Profile</span>

      {currentUser && (
        <Formik
          // validateOnBlur={t}
          // validateOnBlur={true}
          // validateOnChange={true}
          enableReinitialize={true}
          initialValues={getUpdateInitialValues(currentUser)}
          validationSchema={validationSchema}
          onSubmit={async (values, { props, setSubmitting }) => {
            // let data = {
            //   productOrderItemsIds: JSON.parse(atob(id)),
            //   ...getReqBody(values),
            // };
            // dispatch(purchaseOrder({ data }));
            dispatch(updateUserData({ data: getReqBody(values) }));
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
            isValid,
            isSubmitting,
            dirty,
          }) => (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className={"flex flex-col gap-8 w-full"}
            >
              <div className='grid md:grid-cols-2 gap-3 md:gap-5 w-full'>
                <Input
                  size='md'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",
                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  isRequired={true}
                  label={"First name"}
                  placeholder={"Enter your first name"}
                  name='firstName'
                  value={values.firstName}
                  type={"text"}
                  onChange={getHandleChange(
                    "firstName",
                    setFieldValue,
                    handleChange
                  )}
                />
                <Input
                  size='md'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",
                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  isRequired={true}
                  label={"Last name"}
                  placeholder={"Enter your last name"}
                  name='lastName'
                  value={values.lastName}
                  type={"text"}
                  onChange={getHandleChange(
                    "lastName",
                    setFieldValue,
                    handleChange
                  )}
                />
                <Input
                  size='md'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",

                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  // isInvalid={
                  //   !touched[field.name] && !errors[field.name]
                  // }
                  //   className='text-black dark:text-white rounded-full'
                  startContent={"+998"}
                  // isInvalid={touched[field.name] && Boolean(errors[field.name])}
                  // errorMessage={errors[field.name]}
                  isRequired={true}
                  // style={{ color: "#000", fontWeight: 500 }}
                  label={"Phone number"}
                  // labelPlacement='outside'
                  placeholder={"Enter your phone number"}
                  name='phoneNumber'
                  value={values.phoneNumber}
                  type={"text"}
                  onChange={getHandleChange(
                    "phoneNumber",
                    setFieldValue,
                    handleChange
                  )}
                />
                <Input
                  size='md'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",
                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  isRequired={true}
                  label={"Email"}
                  placeholder={"Enter your email"}
                  name='email'
                  value={values.email}
                  type={"text"}
                  onChange={getHandleChange(
                    "email",
                    setFieldValue,
                    handleChange
                  )}
                />
                <Input
                  size='md'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",
                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  isRequired={true}
                  label={"Birthd date"}
                  placeholder={"Enter your birthd date"}
                  name='birthDate'
                  value={values.birthDate}
                  type={"date"}
                  onChange={getHandleChange(
                    "birthDate",
                    setFieldValue,
                    handleChange
                  )}
                />
              </div>
              <div className='flex w-full flex-row justify-end'>
                <button
                  type='submit'
                  style={{
                    backgroundColor: !isValid || !dirty ? "#bbb" : "#ECC6FF",
                  }}
                  disabled={!isValid || !dirty}
                  className='flex flex-row gap-2 w-fit text-[14px] justify-center items-center bg-[#ECC6FF] rounded-full px-5 py-3'
                >
                  Sava changes
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UserProfilePage;
