import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import date from "date-and-time";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCartItemsForOrder,
  getDeliveryAddressesByRegionId,
  getRegions,
  purchaseOrder,
} from "../userSlice";
import {
  Avatar,
  AvatarGroup,
  Divider,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { getImageSrc } from "../../../utils/image";
import { getMoneyPattern } from "../../../utils/regex";
import { getDates } from "../../../utils/product";
import PaymentTypeRedio from "../../../components/Purchase/PaymentTypeRedio";
import { Formik } from "formik";
import { deliveryHours, geteEmptyValues, validationSchema } from "./data";
import { getHandleChange, getReqBody } from "../../../components/Form/data";

const PurchasePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItemsForOrder, regions, deliveryAddresses } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    let decodedIds = JSON.parse(atob(id));
    dispatch(getCartItemsForOrder({ cartItemIds: decodedIds }));
    dispatch(getRegions());
  }, []);
  return (
    cartItemsForOrder && (
      <Formik
        // validateOnBlur={t}
        // validateOnBlur={true}
        // validateOnChange={true}
        enableReinitialize={true}
        initialValues={geteEmptyValues(getDates()[0])}
        validationSchema={validationSchema}
        onSubmit={async (values, { props, setSubmitting }) => {
          let data = {
            productOrderItemsIds: JSON.parse(atob(id)),
            ...getReqBody(values),
          };
          dispatch(purchaseOrder({ data }));
          console.log(data);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isValid,
          isSubmitting,
          dirty,
        }) => (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className={"flex flex-col gap-8 w-full"}
          >
            <div className='grid md:grid-cols-2 gap-5 w-full'>
              <div className='flex flex-col gap-3 md:gap-5 w-full bg-[#ECC6FF]/30 p-5 rounded-3xl'>
                <span className=' text-[18px] md:text-[22px]'>Delivery</span>
                <Input
                  size='lg'
                  variant='faded'
                  classNames={{
                    inputWrapper: "bg-white rounded-full px-5",

                    input: [
                      "text-black/90 dark:text-white/90",
                      "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                    ],
                  }}
                  // isInvalid={
                  //   !touched[field.name] && !errors[field.name]
                  // }
                  //   className='text-black dark:text-white rounded-full'
                  startContent={"+998"}
                  // isInvalid={touched[field.name] && Boolean(errors[field.name])}
                  // errorMessage={errors[field.name]}
                  isRequired={true}
                  // style={{ color: "#000", fontWeight: 500 }}
                  label={"Phone number"}
                  // labelPlacement='outside'
                  placeholder={"Enter your phone number"}
                  name='phoneNumber'
                  value={values.phoneNumber}
                  type={"text"}
                  onChange={getHandleChange(
                    "phoneNumber",
                    setFieldValue,
                    handleChange
                  )}
                />

                {/* SELECT REGION */}
                {regions && (
                  <Select
                    size='lg'
                    variant='faded'
                    classNames={{
                      trigger: "bg-white rounded-full px-5",
                    }}
                    //   isInvalid={touched[field.name] && Boolean(errors[field.name])}
                    //   errorMessage={errors[field.name]}
                    isRequired={true}
                    // classNames={{
                    //   listboxWrapper: "grid grid-cols-2 border-2 h-40 w-40",
                    //   listbox: "w-20 col-span-2",
                    // }}
                    className='text-black dark:text-white w-full flex flex-row'
                    selectionMode='single'
                    // selectedKeys={values[field?.name]}
                    // name={field?.name}
                    // onSelectionChange={handleChange}
                    selectedKeys={new Set([values.regionId])}
                    label={"Region"}
                    placeholder='Select region'
                    onChange={(e) => {
                      setFieldValue("regionId", e.target.value);
                      dispatch(
                        getDeliveryAddressesByRegionId({ id: e.target.value })
                      );
                    }}
                  >
                    {regions &&
                      regions.map((selData) => (
                        <SelectItem
                          textValue={selData?.name}
                          // className='text-black dark:text-white'
                          key={selData?.id}
                          value={selData?.id}
                        >
                          {selData?.name}
                        </SelectItem>
                      ))}
                  </Select>
                )}

                {/* SELECT DELIVERY ADDRESS */}
                {deliveryAddresses && (
                  <Select
                    size='lg'
                    variant='faded'
                    classNames={{
                      trigger: "bg-white rounded-full px-5",
                    }}
                    //   isInvalid={touched[field.name] && Boolean(errors[field.name])}
                    //   errorMessage={errors[field.name]}
                    isRequired={true}
                    // classNames={{
                    //   listboxWrapper: "grid grid-cols-2 border-2 h-40 w-40",
                    //   listbox: "w-20 col-span-2",
                    // }}
                    className='text-black dark:text-white w-full flex flex-row'
                    selectionMode='single'
                    // selectedKeys={values[field?.name]}
                    // name={field?.name}
                    // onSelectionChange={handleChange}
                    selectedKeys={new Set([values.deliveryAddressId])}
                    label={"Delivery address"}
                    placeholder='Select delivery address'
                    onChange={(e) => {
                      setFieldValue("deliveryAddressId", e.target.value);
                    }}
                  >
                    {deliveryAddresses &&
                      deliveryAddresses.map((selData) => (
                        <SelectItem
                          textValue={selData?.name}
                          // className='text-black dark:text-white'
                          key={selData?.id}
                          value={selData?.id}
                        >
                          <div className='flex flex-col'>
                            <span className='text-[14px] font-bold'>
                              {selData?.name}
                            </span>
                            <span className='text-[12px]'>
                              {selData?.address1}
                            </span>
                          </div>
                        </SelectItem>
                      ))}
                  </Select>
                )}

                {deliveryAddresses?.find(
                  (el) => el.id == values.deliveryAddressId
                )?.isPrivate ? (
                  <div className='flex flex-col w-full gap-5 p-5 bg-white rounded-3xl'>
                    <RadioGroup
                      orientation='horizontal'
                      classNames={{
                        label: "text-[14px]",
                      }}
                      name='deliveryDate'
                      label='Select comfortable date'
                      value={values.deliveryDate}
                      onValueChange={(e) => {
                        setFieldValue("deliveryDate", e);
                      }}
                    >
                      {getDates().map((dh) => (
                        <Radio className='mr-4' size='sm' value={dh}>
                          {date.format(new Date(dh), "MMM DD YYYY")}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <Divider className='my-[2px]' />
                    <RadioGroup
                      orientation='horizontal'
                      classNames={{
                        label: "text-[14px]",
                      }}
                      name='deliveryHours'
                      label='Select comfortable hour'
                      value={values.deliveryHours}
                      onValueChange={(e) => {
                        setFieldValue("deliveryHours", e);
                      }}
                    >
                      {deliveryHours?.map((dh) => (
                        <Radio className='mr-4' size='sm' value={dh.id}>
                          {dh.name}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </div>
                ) : (
                  values.deliveryAddressId && (
                    <div className='flex relative flex-col pb-8 gap-1 bg-white p-5 rounded-3xl'>
                      <img
                        className='w-[80px] absolute top-4 right-4'
                        src='https://bts.uz/img/logo.svg'
                      />
                      <div className='flex flex-row '>
                        <span className='text-[16px] font-bold'>
                          {
                            deliveryAddresses?.find(
                              (el) => el.id == values.deliveryAddressId
                            )?.name
                          }
                        </span>
                      </div>
                      <span className='text-[14px] mt-3'>
                        Address 1:
                        <span className='text-[14px] font-semibold mx-2'>
                          {
                            deliveryAddresses?.find(
                              (el) => el.id == values.deliveryAddressId
                            )?.address1
                          }
                        </span>
                      </span>
                      <span className='text-[14px]'>
                        Address 2:
                        <span className='text-[14px] font-semibold mx-2'>
                          {
                            deliveryAddresses?.find(
                              (el) => el.id == values.deliveryAddressId
                            )?.address2
                          }
                        </span>
                      </span>
                      <span className='text-[14px]'>
                        Map:
                        <Link
                          target='_blank'
                          href={
                            deliveryAddresses?.find(
                              (el) => el.id == values.deliveryAddressId
                            )?.mapData
                          }
                          className='text-[14px] font-semibold mx-2'
                        >
                          View on map
                        </Link>
                      </span>
                    </div>
                  )
                )}
              </div>
              <div className='flex flex-col gap-5 w-full bg-neutral-100 p-5 rounded-3xl'>
                <span className='text-[18px] md:text-[22px]'>Summary</span>
                <div className='flex flex-col md:flex-row text-[16px] justify-between items-start gap-5 md:gap-0 md:items-center w-full px-5'>
                  <div className='flex flex-row items-center gap-5'>
                    <AvatarGroup isBordered>
                      {cartItemsForOrder.map((item) => (
                        <Avatar
                          size='lg'
                          color='primary'
                          className='cursor-pointer'
                          onClick={() =>
                            navigate(
                              `/bracelet/${item?.productVariant?.product?.id}`
                            )
                          }
                          key={item?.id}
                          src={getImageSrc(item?.productVariant?.image)}
                        />
                      ))}
                    </AvatarGroup>
                    <span className='text-[14px] md:text-[16px]'>{`${cartItemsForOrder.reduce(
                      (acc, entry) => acc + entry?.quantity,
                      0
                    )} items`}</span>
                  </div>
                  <span className='font-bold text-[16px]'>{`${getMoneyPattern(
                    cartItemsForOrder.reduce(
                      (acc, entry) =>
                        acc + entry?.quantity * entry?.productVariant?.price,
                      0
                    ),
                    ","
                  )} UZS`}</span>
                </div>

                <div className='flex flex-row text-[16px] justify-between items-center w-full px-5'>
                  <span className='text-[14px] md:text-[16px]'>
                    Delivery Price
                  </span>
                  <span className='font-bold'>
                    {values.deliveryAddressId &&
                    deliveryAddresses?.find(
                      (el) => el.id == values.deliveryAddressId
                    )?.price
                      ? `${getMoneyPattern(
                          deliveryAddresses?.find(
                            (el) => el.id == values.deliveryAddressId
                          )?.price,
                          ","
                        )} UZS`
                      : " - "}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='flex flex-row text-[16px] justify-between items-center w-full px-5'>
                  <span className='text-[18px]'>Total Price</span>
                  <span className='font-bold text-[18px]'>{`${getMoneyPattern(
                    cartItemsForOrder.reduce(
                      (acc, entry) =>
                        acc + entry?.quantity * entry?.productVariant?.price,
                      0
                    ) +
                      (deliveryAddresses?.find(
                        (el) => el.id == values.deliveryAddressId
                      )?.price ?? 0),
                    ","
                  )} UZS`}</span>
                </div>
                <Divider className='my-2' />
                <div className='flex w-full'>
                  <RadioGroup
                    name='paymentType'
                    value={values.paymentType}
                    onValueChange={(e) => {
                      setFieldValue("paymentType", e);
                    }}
                    label='Select payment type'
                    className='w-full'
                    //   description='Selected plan can be changed at any time.'
                  >
                    <div className='flex flex-row w-full justify-center gap-5'>
                      <PaymentTypeRedio description='Payme' value='PAYME'>
                        <img
                          className='w-[80px]'
                          src='https://cdn.payme.uz/help/payme-business-help/ru/resources/payme_01_small.png'
                        />
                      </PaymentTypeRedio>
                      {/* <PaymentTypeRedio description='Click' value='CLICK'>
                        <img
                          className='w-[80px]'
                          src='https://click.uz/click/images/click-white.jpg'
                        />
                      </PaymentTypeRedio> */}
                    </div>
                  </RadioGroup>
                </div>

                <button
                  type='submit'
                  style={{
                    backgroundColor: !isValid || !dirty ? "#bbb" : "#ECC6FF",
                  }}
                  disabled={!isValid || !dirty}
                  className='flex flex-row gap-2 text-[16px] justify-center items-center bg-[#ECC6FF] rounded-full p-5'
                >
                  <img
                    src='https://cdn.hugeicons.com/icons/credit-card-validation-stroke-rounded.svg'
                    alt='credit-card-validation'
                    width='24'
                    height='24'
                  />
                  <span>Buy</span>
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  );
};

export default PurchasePage;
