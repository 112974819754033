export const sidebarItems = [
  {
    name: "Profile",
    path: "/me/profile",
    iconUrl: "https://cdn.hugeicons.com/icons/user-stroke-rounded.svg",
  },
  {
    name: "Cart",
    path: "/me/cart",
    iconUrl:
      "https://cdn.hugeicons.com/icons/shopping-bag-02-stroke-rounded.svg",
  },
  {
    name: "Orders",
    path: "/me/orders",

    iconUrl: "https://cdn.hugeicons.com/icons/package-stroke-rounded.svg",
  },
];
