import React from "react";
import { Checkbox, Link, User, Chip, cn, Avatar } from "@nextui-org/react";
import { useDispatch } from "react-redux";
import {
  addProductToCart,
  removeProductFromCart,
} from "../../pages/UserPage/userSlice";
import { useNavigate } from "react-router-dom";
import { getMoneyPattern } from "../../utils/regex";

const CartItemCheckBox = ({ user, cartItem, value }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Checkbox
      aria-label={
        <span className=' line-clamp-1 text-[32px]'>{user.name}</span>
      }
      classNames={{
        base: cn(
          "inline-flex max-w-full w-full bg-content1 m-0",
          "hover:bg-[#ECC6FF]/20 items-center justify-start",
          "cursor-pointer rounded-3xl gap-2 p-4  border-transparent",
          "data-[selected=true]:border-primary"
        ),
        label: "w-full",
      }}
      value={value}
    >
      <div className='w-full flex justify-between md:gap-2'>
        <div className='flex flex-row items-center gap-2 grow '>
          <Avatar isBordered src={user.avatar} className=' flex-none' />
          <div className='flex flex-col items-start leading-4'>
            <a className='line-clamp-1 text-[12px] md:text-[14px] font-bold'>
              {user?.name}
            </a>
            <span className='text-[12px] text-neutral-600 font-normal'>{`${getMoneyPattern(
              cartItem?.productVariant?.price,
              ","
            )} UZS`}</span>
          </div>
        </div>

        {/* <User
          avatarProps={{ size: "md", src:  }}
          description={
            <span className='text-[12px] text-neutral-600 font-normal'>{`${getMoneyPattern(
              cartItem?.productVariant?.price,
              ","
            )} UZS`}</span>
          }
          name={
            <Link
              color='secondary'
              href={`/bracelet/${cartItem?.productVariant?.product?.id}`}
            >
              {cartItem?.productVariant?.name}
            </Link>
          }
        /> */}
        <div className='flex flex-col flex-none w-fit justify-center gap-1'>
          <div className='flex flex-row gap-2 justify-end md:justify-center items-center md:w-[120px]'>
            <button
              onClick={() => {
                dispatch(
                  removeProductFromCart({
                    id: cartItem?.productVariant?.id,
                  })
                );
              }}
            >
              <img
                src='https://cdn.hugeicons.com/icons/minus-sign-circle-stroke-rounded.svg'
                alt='minus-sign-circle'
                className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]'
              />
            </button>
            <span>{cartItem?.quantity}</span>
            <button
              onClick={() => {
                dispatch(
                  addProductToCart({ id: cartItem?.productVariant?.id })
                );
              }}
            >
              <img
                src='https://cdn.hugeicons.com/icons/plus-sign-circle-stroke-rounded.svg'
                alt='plus-sign-circle'
                className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]'
              />
            </button>
          </div>
        </div>
      </div>
    </Checkbox>
  );
};

export default CartItemCheckBox;
