import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionByName } from "../CollectionsPage/collectionsSlice";
import BraceletCard from "../../components/Bracelet/BraceletCard";

const BraceletsPage = () => {
  const dispatch = useDispatch();
  const { collection } = useSelector((state) => state.collections);

  useEffect(() => {
    dispatch(getCollectionByName({ name: "Wanderlust" }));
  }, []);
  return (
    <div className='w-full flex flex-col py-2 md:py-5'>
      <span className='text-[32px] md:text-[61px] font-semibold text-[#3B3A38]'>
        Bracelets
      </span>

      {collection && (
        <div className='grid grid-cols-2 md:grid-cols-3 w-full mt-2 md:mt-5 gap-3 md:gap-5'>
          {collection?.products.map((el) => (
            <BraceletCard data={el} collectionName={collection?.name} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BraceletsPage;
